<template>
    <main>
        <loading
            :active="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
        />
        <UserHeader />
        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb">
                    <a href="/">
                        <span class="icon-home-outline"></span>
                    </a> /
                    <span>Registro de puntos</span>
                </p>
                <h2>
                    <a href="#" @click="router.go(-1)">
                        <img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington" />
                    </a> Registro de puntos
                </h2>

                <div class="page-content">
                    <div class="tabbed">
                        <input type="radio" id="tab1" name="css-tabs" checked />
                        <input type="radio" id="tab2" name="css-tabs" />
                        <ul class="tabs">
                            <li class="tab">
                                <label for="tab1">Registro de puntos</label>
                            </li>
                            <li class="tab">
                                <label for="tab2">Registro histórico de puntos</label>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="form__control">
                                <form
                                    action
                                    class="form container container--xs color form_point_send"
                                    enctype="multipart/form-data"
                                    @submit.prevent="onSubmitLoadPoint"
                                >
                                    <h3>Solicitud de carga de puntos</h3>
                                    <div class="form__control">
                                        <label class="form__label">Centro de compra:</label>
                                        <v-select
                                            label="item"
                                            v-model="formLoadPoint.shop"
                                            :reduce="item => item.id"
                                            :options="shopRegisterPoint"
                                        ></v-select>
                                        <p
                                            class="form__alert"
                                            v-if="formLoadPointError.errors.shop"
                                        >{{ formLoadPointError.errors.shop[0] }}</p>
                                    </div>
                                    <div class="form__control">
                                        <label
                                            class="form__label"
                                        >Suba imagen del comprobante de pago :</label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            class="form__field"
                                            @change="uploadImageVoucher($event)"
                                        />
                                        <p
                                            class="form__alert"
                                            v-if="formLoadPointError.errors.voucher"
                                        >{{ formLoadPointError.errors.voucher[0] }}</p>
                                    </div>
                                    <div class="form__control">
                                        <label class="form__label">Fecha de solicitud :</label>
                                        <p class="form__warning">
                                            Importante
                                            <br />Al registrar la fecha, recuerde que sólo se aceptará el reclamo de puntos si se realiza como
                                            máximo un día después de la fecha indicada en la imagen del recibo o factura.
                                        </p>
                                        <!-- <input type="text" class="form__field" v-model="formLoadPoint.date_register"> -->
                                        <Datepicker
                                            class="form__field"
                                            v-model="formLoadPoint.date_register"
                                            style="width:40%"
                                        />

                                        <p
                                            class="form__alert"
                                            v-if="formLoadPointError.errors.date_register"
                                        >{{ formLoadPointError.errors.date_register[0] }}</p>
                                    </div>
                                    <div>
                                        <br />
                                        <label
                                            class="form__label"
                                        >Seleccione o digite el artículo a registrar.</label>
                                        <br />
                                        <p
                                            class="text-muted"
                                        >(*) Productos disponibles para las campañas de Club Barrington, de no encontrarse el código a registrar el artículo no está habilitado en nuestra campaña actual</p>
                                        <p
                                            class="text-muted"
                                        >(*) Se pueden ingresar fracciones de metro ejm: 120 cms = 1.2</p>
                                        <div
                                            class="form__control__row"
                                            v-for="(v, k) in arrayItem"
                                            :key="k"
                                        >
                                            <div>
                                                <label class="form_label">Artículo</label>
                                                <!-- <input type="text" class="form__field" v-model="v.item" /> -->
                                                <v-select
                                                    label="item"
                                                    v-model="v.id"
                                                    :options="itemColorList"
                                                ></v-select>
                                            </div>
                                            <div>
                                                <label class="form_label">Metros comprados</label>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    class="form__field"
                                                    v-model="v.meter"
                                                />
                                            </div>

                                            <input type="hidden" v-model="v.point_activity" />
                                        </div>
                                        <p
                                        class="form__alert"
                                        v-if="formLoadPointError.errors.itemEmpty"
                                    >Por favor ingrese algun artículo</p>
                                    </div>
                                    <div>
                                        <div class="pt-1">
                                            <a class="cta" @click="addItem()">Agregar artículo</a>
                                        </div>
                                    </div>
                                    
                                    <div class="text-center mt-md">
                                        <button class="cta">Enviar</button>
                                    </div>
                                </form>
                                <ShopRegisterPointSuccess
                                    :data="shopRegisterPointSuccessData"
                                    v-if="registerPointSuccess"
                                />
                            </div>
                        </div>
                        <!-- <div class="tab-content">
                            <div class="table-normal">
                                <table class="table-normal__content">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Código</th>
                                        <th>Membresía ID</th>
                                        <th>Nombres y Apellidos</th>
                                        <th>Puntos</th>
                                        <th>Estado</th>
                                        <th>Registro</th>
                                    </tr>
                                    <tr v-for="(v, k) in pointActivityData" :key="k">
                                        <td>+</td>
                                        <td>{{ v.id }}</td>
                                        <td>{{ v.user.code_user }}</td>
                                        <td>{{ v.user.first_name }} {{ v.user.last_name }}</td>
                                        <td>{{ Math.ceil(Math.random()*100) }}</td>
                                        <td>
                                            <span
                                                :class="'label ' + getCssStatus(v.status)"
                                            >{{ v.status_label }}</span>
                                        </td>
                                        <td>{{ v.date_register }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref, computed } from "vue";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import API from "@/api";
import store from "@/store";
//import HeadShop from "@/views/private/shop/components/HeadShop";
import UserHeader from "@/views/private/user/components/UserHeader";
import ShopRegisterPointSuccess from "@/views/private/shop/components/ShopRegisterPointSuccess";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "ShopRegisterShop",
    components: {
        //HeadShop,
        UserHeader,
        ShopRegisterPointSuccess,
        Datepicker,
        Loading,
        vSelect
    },
    setup() {
        var isLoading = ref(false);
        const fullPage = true;
        const selectedOption = ref(null);
        const shopRegisterPoint = ref([]);

        const formUserSearch = ref({
            query: "",
            password: "",
            list: []
        });

        const userData = ref({});
        const productForExchangeSelectedText = ref("");
        const formLoadPoint = ref({
            product_for_exchange: "",
            meter: "",
            voucher: null,
            shop: null,
            user: store.state.dataToken.id,
            type_point: "REGPO"
        });
        const formLoadPointError = ref({ errors: {} });
        const formUserAccount = ref([]);
        const formUserAccountError = ref([]);

        const showButtonLoadPoint = ref(true);
        const registerPointSuccess = ref(false);
        const showFileLoadPoint = ref(true);
        const productForExchange = ref([]);
        const shopRegisterPointSuccessData = ref({});
        const pointActivityData = ref([]);
        const formPointActivityDetail = ref({
            item: "",
            meter: ""
        });

        const itemColorList = ref([]);
        const arrayItem = ref([
            {
                item_x_color: "",
                meter: "",
                user: store.state.dataToken.id,
                point_activity: ""
            }
        ]);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        const selectedProductForExchange = e => {
            console.log(
                e.target.options[e.target.options.selectedIndex].text,
                "producto"
            );
            productForExchangeSelectedText.value =
                e.target.options[e.target.options.selectedIndex].text;
        };
        const uploadImageVoucher = event => {
            formLoadPoint.value.voucher = event.target.files[0];
        };

        const checkItem = arr => {
            var flag = false
            arr.forEach(element => {                                
                if ("id" in element && "id" in element.id) {
                    if (element.id.id && element.meter) {                     
                        flag = true
                    }
                }
            });
            return flag
        };
        const onSubmitLoadPoint = () => {
            isLoading.value = true;
            let formData = new FormData();            
            
            for (var key in formLoadPoint.value) {
                formData.append(key, formLoadPoint.value[key]);

            }   
            if (formLoadPoint.value.date_register && formLoadPoint.value.date_register instanceof Date) {                
                formData.append('date_register', formLoadPoint.value.date_register.toISOString().split("T")[0])
            }

            if (checkItem(arrayItem.value)) {
                API.post("/api/point-activity", formData, config)
                    .then(response => {
                        isLoading.value = false;
                        registerPointSuccess.value = true;
                        //debugger;
                        arrayItem.value = arrayItem.value.filter(e => {
                            return e["meter"] && "id" in e && e["id"];
                        });
                        arrayItem.value.map(e => {
                            e["point_activity"] = response.data.id;
                            e["item_x_color"] = e["id"]["id"];
                        });

                        let numSuccess = 0;
                        arrayItem.value.forEach(element => {
                            console.log(element, "ITEM...");
                            savePointActivityDetail(element);
                            numSuccess += 1;
                            if (numSuccess == arrayItem.value.length) {
                                return router.push({
                                    name: "RegisterPointSuccess"
                                });
                            }
                        });
                        //formLoadPointError.value.errors.itemEmpty = true;
                    })
                    .catch(error => {
                        isLoading.value = false;
                        console.log(error, error.response, "ERROR");
                        formLoadPointError.value = error.response.data;
                    });
            } else {
                console.log("FALTA ITEMS");
                isLoading.value = false
                formLoadPointError.value.errors['itemEmpty'] = 'xxxxx'
            }
        };

        const savePointActivityDetail = payload => {
            store
                .dispatch("postPointActivityDetail", payload)
                .then(response => {
                    console.log("OK point activity detail");
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };

        const setShopRegisterPointSuccessData = () => {
            return {
                shop: store.state.dataToken.name,
                typeUser: store.state.dataToken.typeUser,
                membership: store.state.dataToken.code_user,
                fullName:
                    userData.value.first_name + " " + userData.value.last_name,
                productForExchange: productForExchangeSelectedText.value,
                meter: formLoadPoint.value.meter,
                point: userData.value.total_point,
                date_register: formLoadPoint.value.date_register,
                voucher: formLoadPoint.value.voucher.name,
                status: "Pendiente"
            };
        };

        const getUser = () => {
            API.get(
                "/api/user?query=" +
                    formUserSearch.value.query +
                    "&shop_exclude"
            )
                .then(response => {
                    formUserSearch.value.list = response.data;
                    showFileLoadPoint.value = false;
                })
                .catch(error => {
                    formUserAccountError.value = error.response.data;
                });
        };
        const loadPoint = userParam => {
            formLoadPoint.value.user = userParam.id;
            userData.value = userParam;
            showButtonLoadPoint.value = false;
        };

        const getProductForExchange = () => {
            API.get("/api/product-for-exchange").then(response => {
                productForExchange.value = response.data;
            });
        };

        const getPointActivity = () => {
            API.get("/api/point-activity").then(response => {
                pointActivityData.value = response.data;
            });
        };

        const getItemColor = () => {
            store.dispatch("getItemColor").then(response => {
                itemColorList.value = response.data;
            });
        };

        const getCssStatus = v => {
            let css = {
                REJEC: "reject_status_point",
                APPRO: "approved_status_point",
                PENDI: "pending_status_point"
            };
            return css[v];
        };

        const addItem = () => {
            let item2 = {
                item_x_color: "",
                meter: "",
                user: store.state.dataToken.id,
                point_activity: ""
            };
            console.log(item2, "NUEVO");

            arrayItem.value.push(item2);
        };

        const getShopregisterPoint = () => {
            store
                .dispatch("getShopRegisterPoint")
                .then(response => {
                    shopRegisterPoint.value = response.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };

        return {
            itemColorList,
            getItemColor,
            isLoading,
            fullPage,
            router,
            formUserSearch,
            formUserAccount,
            formUserAccountError,
            getUser,
            showButtonLoadPoint,
            loadPoint,
            formLoadPointError,
            formLoadPoint,
            showFileLoadPoint,
            productForExchange,
            getProductForExchange,
            uploadImageVoucher,
            onSubmitLoadPoint,
            store,
            registerPointSuccess,
            shopRegisterPointSuccessData,
            selectedProductForExchange,
            pointActivityData,
            getPointActivity,
            getCssStatus,
            formPointActivityDetail,
            addItem,
            arrayItem,
            getShopregisterPoint,
            shopRegisterPoint
        };
    },
    mounted() {
        this.getProductForExchange();
        this.getPointActivity();
        this.getItemColor();
        this.getShopregisterPoint();
    },
    methods: {
        updateCountry(data, item) {
            data.item = item;
        }
    }
};
</script>

<style scoped>
.page-content {
    /* max-width: 700px;  */
    max-width: 80%;
    margin: 32px auto;
    /* padding: 32px;  */
    background: #fff;
}
/* a {
    color: #21d4fd;
    transition: all 0.3s;
}
a:hover {
    color: #b721ff;
} */

.tabbed {
    /* overflow-x: hidden; */ /* so we could easily hide the radio inputs */
    margin: 32px 0;
    padding-bottom: 16px;
    /* border-bottom: 1px solid #ccc; */
}

.tabbed [type="radio"] {
    /* hiding the inputs */
    display: none;
}

.tabs {
    display: flex;
    align-items: stretch;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #ccc;
}
.tab > label {
    display: block;
    margin-bottom: -1px;
    padding: 12px 15px;
    border: 1px solid #ccc;
    background: #eee;
    color: #666;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
}
.tab:hover label {
    border-top-color: #333;
    color: #333;
}

.tab-content {
    display: none;
    color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed
    [type="radio"]:nth-of-type(5):checked
    ~ .tabs
    .tab:nth-of-type(5)
    label {
    border-bottom-color: #fff;
    /* border-top-color: #B721FF; */
    background: #fff;
    color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
    display: block;
}
</style>